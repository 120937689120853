import React, { useEffect, useState } from "react";
import elementsData from "./Elements.json";
import styled, { css, keyframes } from "styled-components";

const pulse = keyframes`
  0% { transform: scaleX(1); }
  50% { transform: scale3d(1.5, 1.5, 1.5); }
  100% { transform: scaleX(1); }
`;

const BgDiv = styled.div`
  background-image: url("/images/back-background-scratch.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  padding: 2.5rem 2rem;
  gap: 1rem 0;
`;

const AnimatedImage = styled.img<{ animate: boolean }>`
  width: 28%;
  height: 28%;

  ${({ animate }) =>
    animate &&
    css`
      animation: ${pulse} 2s infinite;
    `}
`;

interface Element {
  id: string;
  image: string;
}

interface ScratchCardProps {
  onScratch: (scratched: boolean) => void;
  animate: boolean; // Prop to control animation
}

const Background: React.FC<ScratchCardProps> = ({ onScratch, animate }) => {
  const [matrix, setMatrix] = useState<Element[][]>([]);
  const [scratchComplete] = useState(false);

  useEffect(() => {
    const elements = elementsData as Element[];

    const element1 = elements
      .filter((el) => el.id === "1")
      .slice(0, 1)
      .concat(elements.filter((el) => el.id === "1").slice(0, 1))
      .concat(elements.filter((el) => el.id === "1").slice(0, 1));

    const otherElements = elements.filter((el) => el.id !== "1");
    const extendedElements = [
      ...element1,
      ...otherElements.flatMap((el) => [el, el]), // Repeat each element twice
    ];

    const shuffledElements = extendedElements.sort(() => Math.random() - 0.5);
    const matrix: Element[][] = [];

    for (let i = 0; i < 3; i++) {
      matrix.push(shuffledElements.slice(i * 3, i * 3 + 3));
    }

    setMatrix(matrix);
  }, []);

  useEffect(() => {
    if (scratchComplete) {
      console.log("Scratch complete, triggering animation");
      onScratch(true);
    }
  }, [scratchComplete, onScratch]);

  return (
    <BgDiv>
      {matrix.map((row, rowIndex) =>
        row.map((item, colIndex) => (
          <AnimatedImage
            key={`${rowIndex}-${colIndex}`}
            src={item.image}
            alt={`Element ${item.id}`}
            animate={animate && item.id === "1"}
          />
        ))
      )}
    </BgDiv>
  );
};

export default Background;
