import ScratchCard from "./ScratchCard/scratchCardGame";
import Background from "./ScratchCard/scratchCardBG";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalStyle from "./Fonts/GlobalStyle";
import Headline from "./Headline/Headline";
import styled from "styled-components";
import Modal from "./Modal/Modal";

const Main = styled.div`
  display: flex;
  width: 100vw;
  max-width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: auto !important;
`;

const Scratch = styled.div`
  justify-content: center;
  margin-bottom: 32px;
  align-items: center;
  position: relative;
  max-width: 80vw;
  display: flex;
  width: 20vw;

  @media (max-width: 1440px) {
    width: 30vw;
  }

  @media (max-width: 768px) {
    width: 60vw;
  }

  @media (max-width: 430px) {
    width: 80vw;
    margin-bottom: 32px;
  }

  @media (max-width: 375px) {
    margin-bottom: 20px;
  }

  @media (max-width: 375px) {
    margin-bottom: 10px;
  }
`;

const CardDiv = styled.div`
  position: absolute;
  top: 0;
  display: block;
  margin: auto;
  width: 100%;
`;

const ScratchCardDiv = styled.div``;

const BackgroundDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ButtonDiv = styled.div`
  margin-bottom: 20px;

  button {
    background: none;
    border: none;
    padding: 0;
  }

  @media (max-width: 375px) {
    margin-bottom: 10px;
  }
`;

const Btn = styled.div`
  background-image: url("/images/scratch-btn.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100vw;
  background-size: cover;
  position: relative;

  height: 90px;

  @media (max-width: 430px) {
    height: 34px;
  }

  @media (max-width: 375px) {
    height: 30px;
  }

  @media (max-width: 320px) {
    height: 27px;
  }
`;

const Header = styled.header`
  display: flex;
  width: 100vw;
  background-image: url("/images/header.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 68px;

  @media (max-width: 375px) {
    height: 38px;
  }

  @media (max-width: 375px) {
    height: 34px;
  }

  @media (max-width: 375px) {
    height: 29px;
  }
`;

const App: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [, setScratchedPercent] = useState(0);
  const [animate, setAnimate] = useState(false); // State to control animation
  const [cover] = useState(true);
  const [btn, setBtn] = useState(true);

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = queryParams.get("_token");
  const [token, setToken] = useState<string | null>(tokenFromUrl);

  useEffect(() => {
    // Ensure to declare queryParams within useEffect to capture its scope
    const queryParams = new URLSearchParams(window.location.search);

    if (!token) {
      const newToken = "uuid_1m0tuj63cs76_1m0tuj63cs7666770fa90d6db1.35294397";
      setToken(newToken);

      queryParams.set("_token", newToken);
      navigate(`${window.location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [token, navigate]); // Include token and navigate in the dependency array

  const handleScratch = (scratched: boolean) => {
    if (scratched) {
      setTimeout(() => {
        setShowModal(true);
        setBtn(false);
      }, 2000);
      setAnimate(true);
    }
  };

  const handleScratchedPercentChange = (percent: number) => {
    setScratchedPercent(percent);
    if (percent > 70) {
      handleScratch(true);
    }
  };

  return (
    <Main>
      <GlobalStyle />
      <Header />

      <Headline />

      <Scratch>
        <BackgroundDiv>
          <Background onScratch={handleScratch} animate={animate} />
        </BackgroundDiv>

        <CardDiv>
          <ScratchCardDiv>
            {cover && (
              <ScratchCard
                width={350}
                height={350}
                image="/images/front-background-scratch.svg"
                brushSize={30}
                onScratch={handleScratch}
                onScratchedPercentChange={handleScratchedPercentChange}
              />
            )}
          </ScratchCardDiv>
        </CardDiv>
      </Scratch>

      <ButtonDiv>
        {btn && (
          <button>
            <Btn></Btn>
          </button>
        )}
      </ButtonDiv>

      <footer>
        <img src="/images/footer.svg" alt="" />
      </footer>

      {showModal && <Modal showModal={showModal} token={token} />}
    </Main>
  );
};
export default App;
