import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Counter from "../counter/counter";

const Anchor = styled.a`
  div {
    animation: pulse 2.5s infinite;

    @keyframes pulse {
      0% {
        transform: scaleX(1);
      }
      50% {
        transform: scale3d(1.05, 1.05, 1.05);
      }
      to {
        transform: scaleX(1);
      }
    }
  }
`;
interface ModalProps {
  token: string | null;
  showModal: boolean;
}

const offers = {
  first: [
    {
      text1: "50 rodadas grátis",
      text2: "Deposite R$20",
    },
    {
      text1: "75 rodadas grátis",
      text2: "Deposite R$30",
    },
    {
      text1: "125 rodadas grátis",
      text2: "Deposite R$50",
    },
  ],
};

const Modal: React.FC<ModalProps> = ({ token, showModal }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <img src={`/images/modal-headline.svg`} alt="" className="headline" />

        <div className="offer-list">
          {offers.first.map(({ text1, text2 }) => (
            <Anchor
              key={text1}
              href={`https://cpxpage.cc/?_lp=1&_token=${token}`}
            >
              <div>
                <span>{text1}</span> | {text2}
              </div>
            </Anchor>
          ))}
        </div>

        <a
          className="offer-btn"
          href={`https://cpxpage.cc/?_lp=1&_token=${token}`}
        >
          finalizar cadastro
        </a>

        <footer>
          <img src="/images/cassinopix-logo.svg" alt="" />
        </footer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); // Dark background with 50% opacity

  z-index: 1000;
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  flex-direction: column;
  text-align: center;
  position: relative;
  background: linear-gradient(to bottom, #feeee1, #ecc5a6);
  border: 5px solid #4783ed;
  border-radius: 13px;
  padding: 0 0rem 1rem 0rem;

  .headline {
    margin-top: 1.5rem;
    max-width: 75%;
  }

  .offer-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0 2rem 0;
  }

  a {
    display: flex;
    width: 100%;
    text-decoration: none;
    color: #00070d;
    font-family: "Gotham-Medium";

    div {
      width: 100%;
      padding: 10px 1rem;
      border: 1px solid #00070d;
      border-radius: 8px;
      font-size: 0.75rem;
    }
  }

  div {
    button {
      background: none;
      border: none;
    }
  }

  span {
    font-family: "Gotham-Black";
  }

  @media screen and (min-width: 768px) {
    max-width: 450px;
  }

  h2 {
    color: white;
    font-family: "Gotham-Black";
    font-size: 22px;
  }

  p {
    color: #7d7d84;
    font-family: "Gotham-Medium";
  }

  .offer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    max-width: 60%;
    height: 41px;
    text-transform: uppercase;
    color: #00070d;
    font-family: "Gotham-Medium";
    background: #4783ed;
    padding: 0 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;

    @media (max-width: 375px) {
      font-size: 0.875rem;
    }

    @media (max-width: 320px) {
      font-size: 0.75rem;
    }
  }
`;
