import styled from "styled-components";

// Create a styled img component
const HeadImg = styled.img`
  display: flex;
  width: 250px;
  margin: 2rem auto;

  @media (max-width: 430px) {
    margin: 1rem auto;
    width: 70vw;
  }

  @media (max-width: 375px) {
    width: 65vw;
    margin: 0.75rem auto;
  }

  @media (max-width: 320px) {
    width: 60vw;
    margin: 0.5rem auto;
  }
`;

export default function Headline() {
  return (
    <>
      <HeadImg src="/images/headline.svg" />
    </>
  );
}
